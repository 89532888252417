module.exports = {
	"weight": "重量",
	"width": "宽度",
	"length": "长度",
	"height": "高度",
	"size": "尺寸",
	"destination": "目的地",
	"correctNumber": "请输入正确的数值",
	"freightEstimate": "运费估算",
	"reevaluate": "重新估算",
	"selectParcelType": "请选择你要寄的包裹",
	"fee": "运费",
	"timeliness": "时效",
	"serviceDetail": "服务详情",
	"serviceNotAvailable": "该服务暂不可用",
	"selectService": "选择此服务",
	"correctSizeWeight": "请填写正确的尺寸重量",
	"chooseService": "选择线路服务",
	"chooseReturnService": "选择送件方式",
	"fillParcelInfo": "填写包裹信息",
	"confirmOrder": "确认订单",
	"returnPrevious": "返回上一步",
	"attention": "注意",
	"returnServiceDestIsSend2China": "内陆服务的目的地是Send2China仓库",
	"minimumQty": "最小数量",
	"addressNotAvailableForPickup": "抱歉，您的地址不在我们的可取件范围内",
	"noData": "暂无查询结果",
	"queryingAddressData": "正在查询地址",
	"name": "姓名",
	"enterName": "请输入姓名",
	"englishOnly": "只能输入英文",
	"phone": "电话号码",
	"enterPhone": "请输入电话号码",
	"enterCorrectPhone": "请输入正确的电话号码",
	"postcode": "邮编",
	"enterPostcode": "请输入邮编",
	"enterState": "请输入state",
	"enter5digital": "请输入5位数字",
	"address": "地址",
	"enterAddress": "请输入地址",
	"houseNumber": "门牌号",
	"enterHouseNumber": "请输入门牌号",
	"chineseOnly": "只能输入中文",
	"chooseAddress": "请选择地址",
	"concreteStreet": "具体街道",
	"chineseStreet": "中文街道地址",
	"enterchineseStreet": "请输入中文街道地址",
	"idNumber": "身份证号码",
	"enterIdNumber": "请输入身份证号码",
	"enterCorrectIdNumber": "请输入正确的身份证号码",
	"saveAddressDefault": "设置为默认寄件人(下次下单自动填写该地址)",
	"saveAddressNew": "另存为新的寄件人(保存至地址簿)",
	"pickService": "取件公司",
	"pickTime": "取件时间",
	"pickDate": "取件日期",
	"selectpickDate": "请选择取件日期",
	"note": "备注",
	"changeStore": "修改门店",
	"choosenStore": "选择的门店",
	"continueFillReceiver": "确定，继续填写收件人",
	"addedCart": "已添加到购物车",
	"serviceTerm": "服务条款",
	"termConfirmed": "我已阅读，并确认包裹填写无误",
	"cancel": "取消",
	"alreadySetDefaultReturnService": "您已设置了默认送件方式",
	"clickToModifyReturnService": "点此修改默认送件方式",
	"ifNeedModifyReturnServiceGoBack": "如需修改本次送件方式请选择 返回上一步 进行修改",
	"sender": "寄件人",
	"senderAndCollect": "寄件人(取件)信息",
	"submit": "提交",
	"loadFromAddressBook": "从地址簿导入",
	"edit": "编辑",
	"receiver": "收件人",
	"addReceiver": "添加收件人",
	"selectPromoOrder": "请勾选要优惠的订单",
	"promoCodeApplyed": "打折码已生效",
	"couponApplyed": "优惠券已生效",
	"deleteing": "正在删除...",
	"doneDeleteing": "删除完毕",
	"orderDeleted": "订单已删除",
	"promoCode": "打折码",
	"enterPromoCode": "请输入打折码",
	"use": "使用",
	"redpacket": "红包",
	"select": "请选择",
	"noPacket": "暂无红包",
	"coupon": "优惠券",
	"noCoupon": "暂无优惠券",
	"useYouhui": "使用折扣码/优惠券/红包",
	"handlingOrder": "物流补款",
	"orderNumber": "订单号",
	"service": "服务路线",
	"orderType": "订单类型",
	"claimWeight": "申报重量",
	"realWeight": "实际重量(KG)",
	"shuoming": "说明",
	"handlingFee": "补款金额",
	"parcelorder": "物流订单",
	"expiredAndClick": "订单已过期，请点击右侧查看->编辑",
	"weight_kg": "重量(KG)",
	"jine": "金额",
	"intlfee": "国际运费",
	"returnfee": "境内费用",
	"prepaytax": "预缴税金",
	"additionfee": "附加费",
	"insurancefee": "保险费",
	"discount": "折扣优惠",
	"totalfee": "费用合计",
	"action": "操作",
	"detail": "查看订单",
	"confirmDeleteOrder": "确定删除订单吗?",
	"confirmDelete": "确定删除吗?",
	"ok": "确定",
	"deleteOrder": "删除订单",
	"editOrderCart": "编辑",
	"returnOrder": "境内订单",
	"expireAndReorder": "订单已过期，请删除后重新下单",
	"quantity": "请输入数量",
	"contact": "联系人",
	"pickAddrAndStore": "取件地址/门店信息",
	"mallOrder": "商城订单",
	"productList": "商品明细",
	"recipient": "收货信息",
	"productfee": "商品金额",
	"expressfee": "运费",
	"amount": "总金额",
	"loading": "正在加载...",
	"subtotal": "小计金额",
	"selectall": "全选",
	"returnAndContinueOrder": "返回首页继续下单",
	"needpay": "应付金额",
	"alreadydiscount": "已优惠",
	"checkout": "去结算",
	"deleteSelected": "删除选中的",
	"items": "项",
	"intlService": "国际服务",
	"orderStatus": "订单状态",
	"orderSize": "订单体积",
	"buyExtraInsurce": "额外购买保险",
	"orderCreateAt": "订单日期",
	"parcelQty": "包裹数量",
	"returnService": "回邮服务",
	"pickStore": "自送门店",
	"productName": "物品名称",
	"productCate": "类别",
	"shenbaoPrice": "申报价格",
	"shenbaoQty": "申报数量",
	"hscode": "HS编码",
	"enyterHscode": "请输入HS编码",
	"unitWeight": "单位重量",
	"unitWeightKg": "单位重量(KG)",
	"enterunitWeightKg": "请输入单位重量(KG)",
	"brand": "品牌",
	"unit": "规格/货号",
	"enterunit": "请输入规格/货号",
	"productDetail": "物品明细",
	"feeDetail": "费用明细",
	"noOrder": "还没有任何订单",
	"returnHome": "返回首页",
	"currAmount": "当前总额",
	"amountMayChangeWhenSizeChanged": "修改包裹的尺寸、重量可能会重新计算总额，请知悉",
	"close": "关闭",
	"confirmSave": "确认保存",
	"click": "点击",
	"collapse": "收起",
	"expand": "展开",
	"parcelDetail": "包裹清单",
	"parcel": "包裹",
	"chargableWeight": "计价重量",
	"total": "共",
	"fuelFee": "燃油附加费",
	"addToCart": "添加至购物车",
	"queryAddr": "查询地址",
	"shenbaoExample": "申报范例",
	"sameAddrExceedLimit": "同一地址，超过最大包裹数限制，请增加新收件人！",
	"enterBrand": "请输入品牌",
	"enterGoodsNameCn": "请输入物品名称(中文)",
	"enterGoodsNameCnEn": "请输入物品名称(中英文均可)",
	"sameGoodsNoMoreThan3": "相同商品数量不允许超过3",
	"enterQty": "请输入数量",
	"cate": "分类",
	"selectCate": "请选择分类",
	"selectGoodsOrSearch": "请选择物品或输入关键字搜索",
	"selectGoods": "请选择物品",
	"danjia": "单价",
	"enterdanjia": "请输入单价",
	"shuilv": "税率",
	"tax": "税金",
	"nowAllowedCate": "不允许邮寄：手表/首饰/酒精/奢侈品牌物品/古董艺术品/液体奶",
	"productNameEn": "物品名称(英文)",
	"enterDetailChineseName": "请填写详细的中文物品名称",
	"hsCodeOptional": "HS编码",
	"lookUp": "查询",
	"shipinDetailNotify": "若邮寄食品保健品，规格处需写多少克或多少粒，若邮寄服饰鞋包，则需要写清具体的款式、货号或尺码",
	"parcelValueNoMoreThan3000": "整个包裹价值不允许超过3000元人民币",
	"networkError": "网络错误",
	"pickUpType": "取件方式",
	"addParcel": "添加包裹",
	"currTax": "当前税金",
	"freeTax50rmb": "免税额50rmb",
	"currTotalVal": "当前总价值",
	"goodsTotalTax": "物品总税金",
	"parcelGoodsDetail": "包裹物品明细",
	"parcelWeightandSize": "包裹重量/尺寸",
	"parcelNote": "包裹备注（仅个人可见）",
	"insuranceLimit": "上限丢失保险",
	"selectInsurance": "请选择保险",
	"insuranceService": "保险服务",
	"additionalService": "增值服务费",
	"buyAdditionalService": "增值服务",
	"selectAdditionalService": "请选择增值服务",
	"deleteParcel": "删除此包裹",
	"weightMustMoreThan01kg": "重量至少大于0.1kg",
	"heightMustMoreThan1cm": "高度至少大于1cm",
	"widthMustMoreThan1cm": "宽度至少大于1cm",
	"lengthMustMoreThan1cm": "长度至少大于1cm",
	"length_cm": "长度(CM)",
	"deleteReceiver": "删除收件人",
	"exceedMaxGoods": "已超出最大可申报数量",
	"saveParcelFillAutoNext": "保存本次包裹申报信息，方便下次快速填单",
	"enterHaojiName": "请输入一个好记的快捷申报方案名称",
	"quickShenbao": "快捷申报",
	"save": "保存",
	"delete": "删除",
	"schemaUpdated": "方案已更新",
	"selectShenbao": "请选择要填充的申报",
	"update": "更新",
	"storeName": "门店名称",
	"checkStoreDetail": "查看该门店详情",
	"dropOffAddr": "投递点地址",
	"dropOff": "投递点",
	"whatservice": "提供的服务",
	"dropOffInfo": "投递点信息",
	"openTime": "营业时间",
	"contactTel": "联系电话",
	"selectThisStore": "选择此门店",
	"goSofortPay": "点击下方按钮会跳转到Sofort网站进行支付",
	"goBarclayPay": "点击下方按钮会跳转到Barclay网站进行支付",
	"unpaid": "待支付",
	"generateAlipayQRcode": "点击生成付款二维码",
	"alipayNotify": "注：扫码付款后，请勿关闭本页面，待页面显示付款完成后再关闭！",
	"wechatNotify": "注：扫码付款后，请勿关闭本页面，待页面显示付款完成后再关闭！",
	"PayByBalance": "用余额完成支付",
	"yourBalance": "您的余额",
	"balcenNotEnough": "余额不足，请先",
	"chongzhi": "充值",
	"cannotFetchPayResult": "未能获取支付结果",
	"paySuccess": "支付成功",
	"toPayAmount": "支付金额",
	"yourOrderHasCreated": "您的订单已经完成！",
	"youCanGoTo": "您现在可以进入",
	"confirmAndPay": "确认无误, 去支付",
	"orderList": "订单列表",
	"choosePageCheckAndDownload": "选择相应分页进行查看或下载面单等操作。",
	"luggageOrderYoucango": "行李寄存订单请您进入",
	"toDownloadLabel": "进行下载并打印面单",
	"myLuggage": "我的寄存",
	"orYouCan": "也可以",
	"printLabelNotice": "打印快递面单时要确保印出来的条码足够清晰，请将面单贴在箱子面积最大的一面，然后可以根据下单时选择的投递服务，送去相应的投递点或等待司机上门取件。",
	"returnHomeText": "返回首页",
	"payQuickly": "订单交易成功, 请尽快付款!",
	"payIn24HorCancel": "请您在24小时内完成支付，否则订单会自动取消",
	"selectPayWay": "选择支付方式",
	"submitted": "已提交",
	"product": "商品",
	"jianzhuang": "件装",
	"packageCart": "包材购物车",
	"goShopping": "再去逛逛",
	"zongji": "总计",
	"mallManjian": "包材商城满以下金额免运费",
	"goBackShopping": "返回继续购物",
	"okGoCart": "确认，去购物车",
	"shoujia": "售价",
	"combine": "组合",
	"buyNow": "马上购买",
	"tuWenDetail": "图文详情",
	"lookAndLook": "看了又看",
	"checkDetail": "查看详情",
	"goPackageCart": "进入包材购物车",
	"max5Attachments": "最多上传5个附件",
	"uploadSuccess": "上传成功",
	"uploadFail": "上传失败",
	"waitAdminReply": "待官方回复",
	"waitClientReply": "待客户回复",
	"closed": "已关闭",
	"noReply": "暂无回复",
	"cpmNumber": "工单号",
	"title": "标题",
	"relativeNumber": "关联订单号",
	"status": "状态",
	"clinetTag": "客户标签",
	"adminTag": "官方标签",
	"lastReply": "最后回复",
	"createdAt": "创建日期",
	"xiangqing": "详情",
	"cpmDesc": "问题件系统是帮助客户进行包裹问题反馈及售后跟进的客服系统。",
	"cpmLongDesc": "如您的包裹有异常状态需要进行反馈查询，如包裹无追踪、物流长时间无更新、包裹破损/丢失、退运等问题；或者包裹在抵达速递中国仓库后有其他异常情况，如外箱破损、无法发出等，我们会通过此系统对您进行通知，您可以仓库直接进行沟通回复处理意见。",
	"cpmAllStatus": "全部状态",
	"cpmStatusCreated": "已创建",
	"cpmStatusWait": "等待处理",
	"cpmStatusProcessed": "已完成",
	"cpmSearch": "搜索关键字",
	"basicInfo": "基本信息",
	"wu": "无",
	"attachment": "附件",
	"chatLog": "沟通记录",
	"enterYourReply": "在此输入您的回复",
	"uploadAttachment": "上传附件",
	"clickOrDrag": "点击或拖拽文件到此处",
	"width_cm": "宽度(CM)",
	"height_cm": "高度(CM)",
	"size_cm": "尺寸(CM)",
	"enter6digital": "请输入6位数字",
	"enter8digital": "请输入8位数字",
	"sadOrders": "报关订单",
	"serviceMoreDesc": "服务介绍",
	"z4pdxw": "单个物品重量",
	"isCommercialPackage": "（选填）这个包裹是否属于公司商业包裹？",
	"vatNumber": "VAT税号(选填)",
	"vatNumberFormatError": "格式错误，请按GB+9位数字的格式填写",
	"addrInfo": "地址信息"
};